import React from "react";
import DevelopmentPageTemplate from "../../templates/development-page";
import "../../threeplay.scss";
import '../../styles/all.sass'

export default class Index extends React.Component {
  render() {
    var overview = (
      <>
        <p>
          Below, you can find starter documentation around commonly used styles, components, and page templates that you can use to quickly get started.
        </p>
        <p>
          3Play Media's customer-facing account system is built off of Bootstrap 4. Many of our components are pulled directly from Bootstrap 4, or are customized to follow the 3Play brand colors and styles. If you don't see something here you need, feel free to <a href="/contact" rel="noopener noreferrer">request a new template or starter kit</a> to be created.
        </p>
      </>
    );

    var codeString_ruby = `
    - content_for :title do
      Page Title Here

    - content_for :head do
      = stylesheet_link_tag "app/stylesheet_name"

    - content_for :nav

    - content_for :breadcrumbs do
      %ul.breadcrumb
        %li
          %a Breadcrumb Name

    %div#header-bar
      %h1 Page Name Here

    = react_component("app/component_directory/ComponentName", {})
    `;

    var codeString_react = `
    import React, { useState } from "react";
    import { StyleSheet, css } from 'aphrodite';
    import PropTypes from "prop-types";

    // Insert Bootstrap Imports Here
    import Col from 'react-bootstrap/Col';
    import Container from 'react-bootstrap/Container';
    import Row from 'react-bootstrap/Row';

    // Insert Components Here

    function ComponentName() {
      const [var1, var2] = useState([]);

      function functionName(param1, param2){
      }

      return (
        <>
          <Container fluid="true">
            <Row>
              <Col sm={8}>
              </Col>
              <Col sm={4}>
              </Col>
            </Row>
          </Container>
        </>
      );
    }

    ComponentName.propTypes = {
    };

    const styles = StyleSheet.create({
    });
    export default ComponentName;
    `;

    var code_image = (
      <>
        <h2>Bootstrap 4 Starter Template</h2>
        <img src="/img/starter-template.png" alt="Starter template for 3Play Media web app pages"></img>
        <br />
      </>
    );

    var codes = [
      {
        code: code_image,
        codeLanguage: "haml",
        codeString: codeString_ruby
      },
      {
        codeLanguage: "jsx",
        codeString: codeString_react
      }
    ]

    return (
      <DevelopmentPageTemplate
        title="Starter Kit"
        overview={overview}
        codes={codes}
      />
    );
  }
}
